import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { View, TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { openUrl } from './Phone/Offers/Offer'
import { fetchViewer } from '../../actions'

const VipWebinarActions = props => {
  const dispatch = useDispatch()

  return (
    <View style={props.style}>
      <Button onPress={() => openUrl(props.purchaseUrl)}><ButtonText>Purchase VIP Ticket</ButtonText></Button>
      <Button onPress={() => dispatch(fetchViewer(props.token))}><ButtonText>Check VIP Status</ButtonText></Button>
    </View>
  )
}

VipWebinarActions.propTypes = {
  style: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  purchaseUrl: PropTypes.string.isRequired,
}

const ButtonText = styled.Text`
  font-size: 15px;
  font-weight: 500;
  color: white;
`
const Button = styled(TouchableOpacity)`
  margin-top: 20px;
  border-radius: 12px;
  border: none;
  padding: 12px 18px;
  background-color: #ff7e5c;


  &:hover {
    background: #ff6a43;
  }
`

export default styled(VipWebinarActions)`
  margin-top: 20px;
`

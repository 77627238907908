import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { View, Text, TextInput, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { BlurView } from 'expo-blur'
import { useSelector } from 'react-redux'
import axios from 'axios'
import secrets from '../../config/secrets'
import i18n from '../../services/i18n'
import visitor from '../../services/visitor'

const MessageForm = React.memo(props => {
  const { cid, video_id } = useSelector(state => state.viewer.data)
  const [mode, setMode] = useState('message')
  const [text, setText] = useState('')
  const textFieldRef = useRef(null)

  const handleSubmit = e => {
    e.preventDefault()

    if (!text || !text.length) return

    let appearIn
    if (visitor.viewer_session.is_replay) appearIn = Math.trunc(visitor.time * 1000)

    const payload = { cid }
    if (mode === 'message') {
      payload.message = {
        message: text,
        appear_in: appearIn
      }
    } else {
      payload.question = {
        question: text,
        appear_in: appearIn
      }
    }

    axios({
      url: `${secrets.domain}/meeting/${mode === 'message' ? 'messages' : 'questions'}`,
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      data: payload
    })
    .then(() => {
      textFieldRef.current.focus()
    })
    .catch(error => {
      console.log('error', error)
      textFieldRef.current.focus()
    })

    setText('')
    // setTimeout(() => {
    //   console.log("textFieldRef.current", textFieldRef.current)
    //   console.log("textFieldRef.current.wrappedInstance", textFieldRef.current.wrappedInstance)
    //   textFieldRef.current.wrappedInstance.focus()
    // }, 0)
  }

  const handleChange = val => {
    setText(val)
  }

  const toggleMode = () => {
    setMode(mode === 'message' ? 'question' : 'message')
  }

  return (
    <Container>
      <StyledBlurView intensity={40}>
        <StyledInput
          autoFocus
          ref={textFieldRef}
          placeholder={mode === 'message' ? i18n.t('chat.message_placeholder') : i18n.t('qa.question_placeholder')}
          placeholderTextColor="#8b8f99"
          value={text}
          onChangeText={handleChange}
          onSubmitEditing={handleSubmit}
        />
        <Switcher onPress={toggleMode}>
          {props.switcher && <SwitcherText>{ mode === 'message' ? 'Q' : 'M' }</SwitcherText>}
        </Switcher>
      </StyledBlurView>
    </Container>
  )
})
MessageForm.propTypes = {
  style: PropTypes.array,
  videoId: PropTypes.number,
  onAdd: PropTypes.func,
  switcher: PropTypes.bool,
}

MessageForm.defaultProps = {
  switcher: true,
}

const StyledBlurView = styled(BlurView)`
  border-radius: 20px;
`

const Switcher = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  right: 10px;
  padding: 8px;
`

const SwitcherText = styled.Text`
  font-size: 24px;
  color: #3bbf9e;
`

const StyledInput = styled.TextInput`
  height: 45px;
  background-color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.11)' : '#f2f2fa'};
  border-radius: 25px;
  padding-right: 50px;
  padding-left: 15px;
  font-size: 17px;
  border: none;
  font-family: Gilroy-Regular;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#757f99'};
`

const Container = styled.View`
  margin-top: 16px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  flex: 0 0 auto;
`

export default MessageForm

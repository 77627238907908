import Bugsnag from '@bugsnag/react-native'
import { AppState, Platform, Linking } from 'react-native'
import * as Updates from 'expo-updates'
import * as Device from 'expo-device'
import * as Localization from 'expo-localization'
import axios from 'axios'
import secrets from '../config/secrets'
import { removeSession, finishSession, startSession, createViewerApplication } from '../actions'
import notifications from './notifications'
import { EventEmitter } from './events'
import storage from './storage'
import getScreenInfo from './screen'
import i18n from './i18n'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

class Visitor {
  constructor() {
    this.appInfo = {
      v: Updates.manifest.version,
      ot: Platform.OS,
      on: Device.osName,
      ov: Device.osVersion,
      l: Localization.locale.split('-')[0]
    }
    this.polls = {}
    this.sawMap = {}
    this.appState = AppState.currentState
    this._handleAppStateChange = this._handleAppStateChange.bind(this)
    AppState.addEventListener('change', this._handleAppStateChange)
  }

  init(session, token, dispatch, callbackFn) {
    console.log('INIT token:', token)
    this.token = token
    const isDesktop = ['desktop'].includes(getScreenInfo.deviceType)
    this.isDesktop = isDesktop

    if (mobile) {
      notifications.init(session, (cid, payload) => {
        dispatch(createViewerApplication(cid, payload))
      })
    }

    i18n.locale = session.webinar.locale || 'en'
    if (this.removeSessionTimeoutId) {
      clearTimeout(this.removeSessionTimeoutId)
    }

    if (this.viewer_session) {
      if (this.viewer_session.cid !== session.cid) {
        console.log('[visitor] re-init', this.status)
        if (mobile || !isDesktop) {
          storage.set('cid', token, session.video.duration)
        }
        this.polls = {}
        clearInterval(this.timer)
        this.trackLeave()
      }
    } else if (mobile || !isDesktop) {
      storage.set('cid', token, session.video.duration)
    }

    this.viewer_session = session
    session.session_polls.forEach(s => {
      if (s.performed) {
        this.polls[s.cta_id] = s.answers
      }
    })
    if (session.left_at_time || session.left_at_time === 0) {
      this.lastLeaveAt = session.left_at_time
      this.firstReplay = false
    } else {
      this.firstReplay = !session.watched_all_offers
    }
    this.viewedOfferIds = []
    this.triggeredOffers = []
    session.session_offers.forEach(o => {
      this.triggeredOffers.push(o.cta_id)
    })

    this.dispatch = dispatch
    this.status = 'initial'
    if (this.viewer_session.is_replay) {
      this.viewer_session.is_rewatch && this._redirectCloseout()

      this.showControls = this.viewer_session.show_controls
      const replayStartTime = this.viewer_session.replay_start_time
      if (replayStartTime) {
        const [h, m, s] = replayStartTime.split(':')
        const startTimeSeconds = parseInt(h) * 60 * 60 + parseInt(m) * 60 + parseInt(s)
        this.time = Math.max(startTimeSeconds, 0)
      } else {
        this.time = this.lastLeaveAt || 0
      }
      this.startWebinar()
    } else {
      this.showControls = false
      const initialTime = session.sec_remains * -1
      if (initialTime > session.video.duration) {
        const expireUrl = session.expire_url
        if (expireUrl && expireUrl.length) {
          this.openUrl(expireUrl)
        }

        this.finishWebinar({ skipRedirect: true })
      } else {
        this.startTimer(initialTime)
      }
    }
    this.trackView()

    if (typeof callbackFn === 'function') callbackFn()
  }

  cleanSession() {
    if (this.timer) clearInterval(this.timer)
    this.viewer_session = null
    this.status = null
    this.polls = {}
  }

  startWebinar() {
    if (this.status !== 'start') {
      console.log('[visitor.js] startWebinar')
      this.status = 'start'
      EventEmitter.dispatch('start', this.time)
      this.dispatch(startSession())
      this.trackStart()
    }
  }

  finishWebinar(opts = { skipRedirect: false }) {
    const { skipRedirect } = opts

    if (this.status !== 'end') {
      console.log('[visitor.js] finishWebinar', this.timer)
      clearInterval(this.timer)
      this.status = 'end'
      EventEmitter.dispatch('end', this.time)
      this.trackFinish()
      storage.remove('cid')
      setTimeout(() => {
        this.dispatch(finishSession())
      }, 1000)

      if (!skipRedirect) {
        const finishRedirectUrl = this.viewer_session.webinar.finish_redirect_url
        console.log('finishRedirectUrl', finishRedirectUrl, this.viewer_session)
        if (finishRedirectUrl && finishRedirectUrl.length) {
          this.openUrl(finishRedirectUrl)
        }
      }

      if (!this.removeSessionTimeoutId) {
        this.removeSessionTimeoutId = setTimeout(() => {
          this.dispatch(removeSession())
        }, 30000)
      }
    }
  }

  startTimer(initialTime) {
    console.log('[visitor.js] startTimer', initialTime)
    if (this.timer) clearInterval(this.timer)
    this.time = initialTime
    this.timer = setInterval(() => this.timerTick(), 1000)
    this.timerTick()
  }

  tickScreenSaver() {
    if (this.screenSaver) {
      this.screenSaver.webContents.send('TIME_TICK', this.time)
    }
  }

  timerTick() {
    const { video } = this.viewer_session
    this.time += 1
    EventEmitter.dispatch('time', this.time)
    this.tickScreenSaver()

    if (this.time >= 0 && this.time < video.duration) {
      this.startWebinar()
    }

    if (this.time > video.duration) {
      this.finishWebinar()
    }

    if (Math.ceil(this.time) % 10 === 0 && this.time < 0) {
      this.sync()
    }

    const rawPingInterval = Math.floor(video.duration / 15)
    const pingInterval = Math.max(60, rawPingInterval)

    if (Math.ceil(this.time) % pingInterval === 0) {
      this.trackPing()
    }
  }

  sync(callback) {
    const url = `${secrets.domain}/meeting/viewers/${this.viewer_session.cid}/sync`

    return axios({ url })
    .then(response => {
      const newTime = response.data.viewer_session.sec_remains * -1
      if (Math.abs(newTime - this.time) > 10) {
        this.time = newTime
        callback && callback()
      }
    })
    .catch(() => {
      console.log('sync viewer error')
    })
  }

  setTime(time) {
    this.time = time
  }

  answerPoll(cta_id, answers) {
    this.polls[cta_id] = answers
  }

  triggerOffer(cta_id) {
    if (!this.triggeredOffers.includes(cta_id)) {
      this.triggeredOffers.push(cta_id)
    }
  }

  getDefaultParams() {
    return {
      account_id: this.viewer_session.webinar.account_id,
      webinar_id: this.viewer_session.webinar_id,
      webinar_session_id: this.viewer_session.webinar_session_id,
      video_id: this.viewer_session.video_id,
      cid: this.viewer_session.cid,
      token: this.viewer_session.viewer_token,
      time: this.time
    }
  }

  simpleTrack(event_type, params = {}) {
    this.track(event_type, {
      ...this.getDefaultParams(),
      ...params
    })
  }

  trackView(params = {}) { this.simpleTrack('view', params) }

  trackLeave(params = {}) { this.simpleTrack('leave', params) }

  trackStart(params = {}) { this.simpleTrack('start', params) }

  trackFinish(params = {}) { this.simpleTrack('finish', params) }

  trackEnd(params = {}) { this.simpleTrack('end', params) }

  trackPing(params = {}) { this.simpleTrack('ping', params) }

  trackViewOffer(message_id, cta_id, params = {}) {
    if (this.sawMap[message_id]) return
    this.sawMap[message_id] = 1

    this.track('view_offer', {
      ...this.getDefaultParams(),
      message_id,
      cta_id,
      ...params
    })
    this.trackSawCtaCode()
  }

  trackClickOffer(message_id, cta_id, params = {}) {
    this.track('click_offer', {
      ...this.getDefaultParams(),
      message_id,
      cta_id,
      ...params
    })
  }

  trackViewPoll(message_id, cta_id, params = {}) {
    if (this.sawMap[message_id]) return
    this.sawMap[message_id] = 1

    this.track('view_poll', {
      ...this.getDefaultParams(),
      message_id,
      cta_id,
      ...params
    })
    this.trackSawCtaCode()
  }

  trackAnswerPoll(message_id, cta_id, poll_answers, params = {}) {
    this.track('answer_poll', {
      ...this.getDefaultParams(),
      message_id,
      cta_id,
      poll_answers,
      ...params
    })
  }

  trackViewMessage(message_id, cta_id, params = {}) {
    if (this.sawMap[message_id]) return
    this.sawMap[message_id] = 1

    this.track('view_message', {
      ...this.getDefaultParams(),
      message_id,
      cta_id,
      ...params
    })
    this.trackSawCtaCode()
  }

  track(event_name, params, callback) {
    const payload = {
      event: {
        ...params,
        type: event_name,
        platform: this.isDesktop ? 'desktop' : 'mobile',
        app_info: this.appInfo
      }
    }

    axios
    .post(`${secrets.domain}/meeting/viewers/track`, payload)
    .then(response => {
      if (typeof callback === 'function') {
        callback(response)
      }
    })
  }

  trackVideoStats(type) {
    this.simpleTrack(type)
  }

  trackSawCtaCode() {
    EventEmitter.dispatch('tracking', 'saw_cta')
  }

  openUrl(url, options = {}) {
    if (Platform.OS === 'web') {
      window.parent.postMessage({ ...options, type: 'openUrl', url }, '*')
    } else {
      Linking.openURL(url)
    }
  }

  _handleAppStateChange(nextAppState) {
    if (!this.viewer_session) return

    if (this.appState.match(/inactive|background/) && nextAppState === 'active') {
      EventEmitter.dispatch('stateUpdate', 'active')
      this.sync(() => {
        EventEmitter.dispatch('timeChanged')
      })
    }
    if (nextAppState.match(/inactive|background/) && this.appState === 'active') {
      EventEmitter.dispatch('stateUpdate', 'background')
    }

    this.appState = nextAppState
  }

  _bugsnagError(message, error) {
    console.log('error', error)
    Bugsnag.notify(new Error(message), event => {
      event.addMetadata('errorDetails', error)
    })
  }

  _redirectCloseout() {
    const { enabled, expires_at: expiresAt, redirect_url: redirectUrl } = this.viewer_session.closeout || {}

    if (!enabled || !redirectUrl) return

    const now = new Date()
    const expires = new Date(expiresAt)
    const timeLeft = Math.max(expires.getTime() - now.getTime(), 0)

    if (timeLeft / 1000 > 0) return

    if (Platform.OS === 'web') {
      window.parent.postMessage({ type: 'openUrl', url: redirectUrl }, '*')
    }
  }
}

export default new Visitor()

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, batch } from 'react-redux'
import styled from 'styled-components/native'
import CableService from '../services/cable'
import ScreenSaver from './Webinar/ScreenSaver'
import VipWebinarPlug from './Webinar/VipWebinarPlug'
import WebinarPhone from './Webinar/Phone/index'
import WebinarDesktop from './Webinar/Desktop/index'
import WebinarContextProvider from './Webinar/Context'
import TrackingCodeWindow from './TrackingCodeWindow'
import getScreenInfo from '../services/screen'
import useDataFetch from './Webinar/useDataFetch'
import {
  addMessage,
  updateMessage,
  addQuestion,
  removeMessage,
  updateMessageAnswer,
  removeQuestion,
  addFile,
  addConversation,
  addStickyMessage,
  clearMessages,
  clearQuestions,
  clearStickyMessages,
  addMessages,
  addQuestions,
  fetchStickyMessages,
} from '../actions'
import showMessage from './Webinar/showMessage'
import visitor from '../services/visitor'

const Webinar = props => {
  const { session, status } = props
  const dispatch = useDispatch()

  const loadMoreMessages = useDataFetch('messages', session, {
    onAdd: messages => {
      dispatch(addMessages(messages))
    }
  })

  const loadMoreQuestions = useDataFetch('questions', session, {
    onAdd: questions => {
      dispatch(addQuestions(questions))
    }
  })

  useEffect(() => {
    if (visitor.time > 0 || session.is_replay) {
      batch(() => {
        if (!session.is_replay) {
          dispatch(fetchStickyMessages(session.cid))
        }
        loadMoreMessages()
        loadMoreQuestions()
      })
    }
    CableService.init(session.cid)
    // TODO: check if chat, qa and files are enabled for webinar
    const subscription = CableService.actionCable.subscriptions.create({ channel: 'MessagesV1Channel' })

    subscription
      .on('received', response => {
        const { type, data: message } = response
        console.log('on receive', type, message)

        if (type === 'conversation.created' && session.cid === message.cid) {
          dispatch(addConversation(message.conversation_id))
          return
        }

        switch (type) {
          case 'message':
            if (showMessage(message, session)) {
              dispatch(addMessage(message))
            }

            break
          case 'message.updated':
            if (showMessage(message, session)) {
              dispatch(updateMessage(message))
            }

            break
          case 'message.deleted':
            dispatch(removeMessage(message.id))
            break
          case 'question':
            if (showMessage(message, session)) {
              batch(() => {
                dispatch(addQuestion(message))
                dispatch(addMessage(message))
              })
            }
            break
          case 'question.updated':
            if (showMessage(message, session)) {
              batch(() => {
                dispatch(addQuestion(message))
                dispatch(updateMessageAnswer(message))
              })
            }
            break
          case 'question.deleted':
            batch(() => {
              dispatch(removeQuestion(message.id))
              dispatch(removeMessage(message.id))
            })
            break
          case 'file':
            if (showMessage(message, session)) {
              batch(() => {
                dispatch(addMessage(message))
                dispatch(addFile(message))
              })
            }
            break
          case 'sticky':
            if (showMessage(message, session)) {
              dispatch(addStickyMessage(message))
            }
            break
          default:
          console.log('unknown type:', type)
          break
        }
      })
      .on('connected', () => console.log('ws connected'))
      .on('rejected', () => console.log('ws rejected'))
      .on('disconnected', () => console.log('ws disconnected'))

    return () => {
      batch(() => {
        dispatch(clearMessages())
        dispatch(clearQuestions())
        dispatch(clearStickyMessages())
      })

      if (subscription) {
        subscription.removeListener('received')
        subscription.removeListener('connected')
        subscription.removeListener('rejected')
        subscription.removeListener('disconnected')
        subscription.unsubscribe()
      }
    }
  }, [session?.cid])

  if (status === 'pending') return <ScreenSaver token={props.token} session={session} />
  if (['started', 'fetched'].includes(status) && !session.purchased) return <VipWebinarPlug token={props.token} session={session} />

  // const isDesktop = ['desktop', 'tablet'].includes(getScreenInfo.deviceType)
  const isDesktop = ['desktop'].includes(getScreenInfo.deviceType)

  const WebinarComponent = isDesktop ? WebinarDesktop : WebinarPhone

  return (
    <SectionContainer>
      <WebinarContextProvider session={session}>
        <WebinarComponent loadMoreMessages={loadMoreMessages} loadMoreQuestions={loadMoreQuestions} />
        <TrackingCodeWindow additionalParams={session.tracking_code_params} webinarId={session.webinar_id} isReplay={session.is_replay} />
      </WebinarContextProvider>
    </SectionContainer>
  )
}

Webinar.propTypes = {
  session: PropTypes.object,
  status: PropTypes.string,
  token: PropTypes.string.isRequired
}

const SectionContainer = styled.View`
  flex: 1;
`

export default Webinar

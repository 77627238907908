import AsyncStorage from '@react-native-async-storage/async-storage'

  const getExpireAt = seconds => {
    const now = new Date()
    const expireTime = new Date(now)
    expireTime.setSeconds(now.getSeconds() + seconds)
    return expireTime
  }

  const getKey = key => `@Webby:${key}`

  const handleError = (error, type) => {
      console.log(`storage ${type} ERROR`, error)
  }

const storage = {
  async get(key) {
    try {
      const res = await AsyncStorage.getItem(getKey(key))
      const data = JSON.parse(res)
      if (data?.expireAt) {
        if (new Date(data.expireAt) > (new Date())) {
          return data.value
        }

        await AsyncStorage.removeItem(getKey(key))
        return null
      }

      return null
    } catch (error) {
      handleError(error, 'get')
    }
  },

  async set(key, value, expireSec) {
    try {
      const payload = {
        value,
        expireAt: getExpireAt(expireSec)
      }
      const objToStore = JSON.stringify(payload)
      return await AsyncStorage.setItem(getKey(key), objToStore)
    } catch (error) {
      handleError(error, 'set')
    }
  },

  async remove(key) {
    try {
      return await AsyncStorage.removeItem(getKey(key))
    } catch (error) {
      handleError(error, 'remove')
    }
  },

}

export default storage

import axios from 'axios'
import secrets from '../config/secrets'
import storage from '../services/storage'

const fetchViewerBegin = () => ({ type: 'FETCH_VIEWER_BEGIN' })
export const fetchViewerSuccess = ({ viewer_session }) => ({ type: 'FETCH_VIEWER_SUCCESS', viewer_session })
export const fetchViewerError = error => ({ type: 'FETCH_VIEWER_ERROR', error })

export const setViewerStatus = status => ({ type: 'SET_VIEWER_STATUS', status })

export function fetchViewer(token) {
  if (!token || !token.length) {
    return fetchViewerError('Viewer not found')
  }

  return dispatch => {
    dispatch(fetchViewerBegin())

    return axios({
      url: `${secrets.domain}/meeting/viewers/${token}`
    })
    .then(response => {
      visitor.init(response.data.viewer_session, token, dispatch, () => {
        dispatch(fetchViewerSuccess(response.data))
      })
    })
    .catch(error => {
      dispatch(fetchViewerError(error))
      // handleErrors(error)
    })
  }
}

const syncViewerSuccess = ({ viewer_session }) => ({ type: 'SYNC_VIEWER', viewer_session })
export function syncViewer(token) {
  return dispatch => {
    const url = `${secrets.domain}/meeting/viewers/${token}/sync`

    return axios({
      url
    })
    .then(response => {
      dispatch(syncViewerSuccess(response.data))
      return response.data
    })
    .catch(() => {
      // handleErrors(error)
    })
  }
}

const createViewerApplicationSuccess = ({ viewer_application }) => ({ type: 'CREATE_VIEWER_APPLICATION', viewer_application })
export function createViewerApplication(cid, viewer_application) {
  return dispatch => {
    const url = `${secrets.domain}/meeting/viewer_applications`

    return axios({
      url,
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      data: { cid, viewer_application }
    })
    .then(response => {
      dispatch(createViewerApplicationSuccess(response.data))
      return response.data
    })
    .catch(() => {
      // handleErrors(error)
    })
  }
}

export const removeSession = () => {
  storage.remove('cid')
  return { type: 'REMOVE_SESSION' }
}

export const finishSession = () => {
  storage.remove('cid')
  return { type: 'FINISH_SESSION' }
}

export const startSession = () => ({ type: 'START_SESSION' })
export const quitSession = () => ({ type: 'QUIT_SESSION' })
export const addConversation = conversation_id => ({ type: 'ADD_VIEWER_CONVERSATION', conversation_id })

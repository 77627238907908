import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Platform, Keyboard } from 'react-native'
import styled from 'styled-components/native'
import axios from 'axios'
import { useSelector, useDispatch, batch } from 'react-redux'
import {
  fetchViewerSuccess,
  fetchViewerError,
  removeSession,
  clearMessages,
  clearQuestions,
  clearFiles,
  clearDirectMessages
} from '../actions'
import secrets from '../config/secrets'
import i18n from '../services/i18n'
import getScreenInfo from '../services/screen'
import ConnectPhone from './Connect/ConnectPhone'
import ConnectDesktop from './Connect/ConnectDesktop'

const Connect = props => {
  const { token, setToken } = props
  const error = useSelector(state => state.viewer.error)
  const dispatch = useDispatch()
  const [debugging, setDebugging] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const tokenRef = useRef('')

  useEffect(() => {
    Keyboard.addListener('keyboardDidHide', onTokenSubmit)

    return () => {
      Keyboard.removeListener('keyboardDidHide', onTokenSubmit)
    }
  }, [])

  useEffect(() => {
    if (error) {
      setErrorMessage(i18n.t('app.token_not_found'))
    }
  }, [error])

  const onChangeText = val => {
    if (val?.toLowerCase() === 'webbydbg') {
      setDebugging(true)
    } else {
      setDebugging(false)
    }

    setErrorMessage(null)
    setToken(val)
    tokenRef.current = val
  }

  const onTokenSubmit = () => {
    console.log('fetch', token, secrets.domain)
    const currentToken = token || tokenRef.current
    if (currentToken && currentToken.length) {
      batch(() => {
        dispatch(removeSession())
        dispatch(clearMessages())
        dispatch(clearQuestions())
        dispatch(clearFiles())
        dispatch(clearDirectMessages())
      })

      if (currentToken?.length) {
        axios({ url: `${secrets.domain}/meeting/viewers/${currentToken}` })
          .then(response => {
            visitor.init(response.data.viewer_session, currentToken, dispatch, () => {
              dispatch(fetchViewerSuccess(response.data))
            })
          })
          .catch(e => {
            setToken(currentToken)
            dispatch(fetchViewerError(e))
          })
      }
    } else {
      setErrorMessage(i18n.t('app.token_not_exist'))
    }
  }

  // const isDesktop = ['tablet', 'desktop'].includes(getScreenInfo.deviceType)
  const isDesktop = ['desktop'].includes(getScreenInfo.deviceType)

  if (isDesktop) {
    return (
      <ConnectDesktop
        onSubmit={onTokenSubmit}
        onChangeText={onChangeText}
        debugging={debugging}
        token={token}
        errorMessage={errorMessage}
        behavior={Platform.OS === 'ios' ? 'position' : null}
      />
    )
  }

  return (
    <ConnectPhone
      onSubmit={onTokenSubmit}
      onChangeText={onChangeText}
      debugging={debugging}
      token={token}
      errorMessage={errorMessage}
      behavior={Platform.OS === 'ios' ? 'position' : null}
    />
  )
}

Connect.propTypes = {
  token: PropTypes.string,
  setToken: PropTypes.func.isRequired,
}

export default styled(Connect)`

`

import React from 'react'
import PropTypes from 'prop-types'
import { heightPercentageToDP as hp } from 'react-native-responsive-screen'
import { Platform } from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import * as ScreenOrientation from 'expo-screen-orientation'
import Webinar from './Webinar'
import FinishWindow from './Webinar/FinishWindow'
import Connect from './Connect'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const Home = props => {
  if (mobile) ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
  const { data: session, loading, status } = useSelector(state => state.viewer)

  if (loading) return <ActivityIndicatorStyled size="large" />
  if (status === 'initial') return null
  if (status === 'finished') return <FinishWindow />
  if (!session || status === 'ready') return <Connect token={props.token} setToken={props.setToken} />

  return session ? <Webinar session={session} status={status} token={props.token} /> : null
}

Home.propTypes = {
  token: PropTypes.string,
  setToken: PropTypes.func.isRequired,
}

const ActivityIndicatorStyled = styled.ActivityIndicator`
  margin-top: ${hp(10)}px;
`

export default Home

import { useState, useRef } from 'react'
import axios from 'axios'
import moment from 'moment-timezone'
import secrets from '../../config/secrets'
import { secondsToDuration } from '../../helpers/time'

const fetchMessages = (cid, params, fn) => {
  let qs = `cid=${cid}`
  if (params.direction) {
    qs += `&direction=${params.direction}`
  }
  if (params.nextPosition) {
    qs += `&next_position=${params.nextPosition}`
  }
  axios({
    url: `${secrets.domain}/meeting/messages?${qs}`
  })
  .then(fn)
  .catch(() => {})
}

const fetchQuestions = (cid, params, fn) => {
  let qs = `cid=${cid}`
  if (params.direction) {
    qs += `&direction=${params.direction}`
  }
  if (params.nextPosition) {
    qs += `&next_position=${params.nextPosition}`
  }
  axios({
    url: `${secrets.domain}/meeting/questions?${qs}`
  })
  .then(fn)
  .catch(() => {})
}

const manageResponse = (session, collection, addDataFn) => {
  const visibleMessages = []
  const scheduledMessages = {}
  collection.forEach(item => {
    const scheduleIn = moment.duration(item.appear_in).asSeconds()

    if (!session.is_replay && session.sec_remains * -1 < scheduleIn) {
      scheduledMessages[scheduleIn] = scheduledMessages[scheduleIn] || []
      scheduledMessages[scheduleIn].push(item)
    } else {
      visibleMessages.push(item)
    }
  })
  addDataFn(visibleMessages)
}

const useDataFetch = (entity, session, options = {}) => {
  const { onAdd } = options
  const fetchData = ({ messages: fetchMessages, questions: fetchQuestions })[entity]
  const seconds = session.sec_remains * -1
  const startDuration = seconds < 0 ? null : secondsToDuration(seconds)
  const prevPosition = useRef(session.is_replay ? '-00:00:01' : startDuration)
  const [isNextPageLoading, setNextPageLoading] = useState(false)

  const loadNextPage = () => {
    setNextPageLoading(true)

    const direction = session.is_replay ? 'after' : 'before'
    fetchData(session.cid, { direction, nextPosition: prevPosition.current }, response => {
      manageResponse(session, response.data[entity], onAdd)
      prevPosition.current = response.data.meta.next_position
      setNextPageLoading(false)
    })
  }

  return (isNextPageLoading || !prevPosition.current) ? () => {} : loadNextPage
}

export default useDataFetch

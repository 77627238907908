import React from 'react'
import { View, Text } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import VipWebinarActions from './VipWebinarActions'

const VipWebinarPlug = props => (
  <View style={props.style}>
    <Title>A VIP Session is currently running and it seems you do not have access to it.</Title>
    <Title>You can click the link below to get access or you can check your status.</Title>
    <VipWebinarActions token={props.token} purchaseUrl={props.session.webinar_session.purchase_url} />
  </View>
)

VipWebinarPlug.propTypes = {
  style: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  session: PropTypes.object.isRequired,
}

const Title = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  display: flex;
  text-align: center;
`

export default styled(VipWebinarPlug)`
  background-color: #ffffff;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 30px 20px;
  height: 100%;
`

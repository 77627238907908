import React from 'react'
import { Platform } from 'react-native'
import * as Notifications from 'expo-notifications'
import { Constants } from 'react-native-unimodules'
import * as Updates from 'expo-updates'
import moment from 'moment-timezone'
import storage from './storage'

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

class PushNotifications {
  async init(session, callback) {
    this.session = session
    this.callback = callback
    this.notifications = session.notifications
    this.timeNow = moment(session.time_now)
    this.startTime = moment(session.scheduled_at)

    const permissions = await this.checkPermissions()

    if (!permissions) { return null }

    this.checkViewerApplication()
  }

  async checkViewerApplication() {
    const application = this.session.applications.find(app => app.device_id === Constants.deviceId)

    if (!application || !application.push_token || !application.viewer_id || !application.project) {
      const token = await this.getExpoPushToken()
      const payload = { push_token: token, device_id: Constants.deviceId, project: Updates.manifest.id }
      this.callback(this.session.cid, payload)
    }
  }

  async checkPermissions() {
    if (Constants.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync()

      let finalStatus = existingStatus

      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }

      if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
      }

      return finalStatus === 'granted'
    }

    return false
  }

  async getExpoPushToken() {
    const storedToken = await storage.get('expoPushToken')
    if (storedToken) { return storedToken }

    const token = (await Notifications.getExpoPushTokenAsync({ experienceId: '@oleghaidul/webby' })).data
    storage.set('expoPushToken', token, moment.duration(1, 'y').asSeconds())
    return token
  }

  // scheduleLocalNotifications() {
  //   Notifications.cancelAllScheduledNotificationsAsync()

  //   this.notifications.forEach(notification => {
  //     let scheduleTime
  //     if (notification.offset_type === "before") {
  //       scheduleTime = this.startTime.subtract(notification.hours, 'hours').subtract(notification.minutes, 'minutes')
  //     } else {
  //       scheduleTime = this.startTime.add(notification.hours, 'hours').add(notification.minutes, 'minutes')
  //     }
  //     // scheduleTime = scheduleTime.add('2', 'minutes').toDate()
  //     scheduleTime = scheduleTime.toDate()

  //     if (this.timeNow < scheduleTime) {
  //       const message = this.createMessage(notification.subject, notification.body)
  //       Notifications.scheduleNotificationAsync(message, { time: scheduleTime })
  //     }
  //   })
  // }

  createMessage(title, body) {
    const config = {
      ios: {
        sound: true
      }
    }

    return { ...config, title, body }
  }
}

export default new PushNotifications()

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Platform, FlatList } from 'react-native'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import styled from 'styled-components/native'
import MessageForm from './MessageForm'
import Message from './Message'
import { selectMessages } from '../../selectors'
import StickyMessages from './StickyMessages'
import visitor from '../../services/visitor'

const renderItem = ({ item }) => (
  <Message
    id={item.id}
    author={item.author}
    answeredBy={item.answered_by}
    appearIn={item.appear_in}
    ctaId={item.webinar_cta.id}
    data={item.data}
    type={item.type}
  />
)

const Chat = props => {
  const messages = useSelector(selectMessages)
  const firstMessage = messages[0]
  const flatList = useRef(null)
  const prevScrollOffset = useRef()
  const prevFirstItem = useRef()
  const [isBottom, setIsBottom] = useState(!visitor.viewer_session.is_replay)

  useEffect(() => {
    if (flatList.current && isBottom && !visitor.viewer_session.is_replay && messages.length) {
      requestAnimationFrame(() => {
        flatList.current.scrollToIndex({ index: messages.length - 1, animated: true })
      })
    }
  }, [messages.length])

  useEffect(() => {
    if (flatList.current && !visitor.viewer_session.is_replay && messages.length) {
      requestAnimationFrame(() => {
        flatList.current.scrollToIndex({ index: messages.length - 1, animated: false })
      })
    }
  }, [messages.length > 0])

  useEffect(() => {
    if (firstMessage) {
      if (prevFirstItem.current && prevFirstItem.current !== firstMessage) {
        if (flatList.current) {
          flatList.current && flatList.current.scrollToItem({ item: prevFirstItem.current, animated: false })
        }
      }

      prevFirstItem.current = firstMessage
    }
  }, [firstMessage && firstMessage.id])

  const onContentSizeChange = () => {
    // if (flatList.current && messages.length && !isScrolling.current) {
    //   requestAnimationFrame(() => {
    //     flatList.current.scrollToIndex({ index: messages.length - 1, animated: false })
    //   })
    // }
  }

  const onScrollToIndexFailed = debounce(error => {
    const offset = error.averageItemLength * error.index
    if (flatList.current) {
      flatList.current.scrollToOffset({ offset, animated: true })
    }
    requestAnimationFrame(() => {
      if (flatList.current) {
        flatList.current.scrollToIndex({ index: error.index, animated: true })
      }
    })
  }, 50, { leading: true })

  useEffect(() => () => {
      onScrollToIndexFailed.cancel()
    }, [])

  const onScroll = e => {
    const scrollOffset = e.nativeEvent.contentOffset.y

    let scrollDirection = scrollOffset > prevScrollOffset.current ? 'forward' : 'backward'
    if (!prevScrollOffset.current) {
      // first scroll
      scrollDirection = 'forward'
    }

    if (prevScrollOffset.current && prevScrollOffset.current === scrollOffset) {
      // end of scrolling
      return
    }

    prevScrollOffset.current = scrollOffset

    const paddingToBottom = 20
    const atBottom = e.nativeEvent.layoutMeasurement.height + e.nativeEvent.contentOffset.y >= e.nativeEvent.contentSize.height - paddingToBottom

    if (visitor.viewer_session.is_replay) {
      setIsBottom(atBottom)
      if (atBottom && messages.length) {
        props.loadMoreItems()
      }
    } else {
      setIsBottom(atBottom)

      if (Platform.OS === 'web') {
        if (scrollDirection === 'backward' && scrollOffset < 5) {
          props.loadMoreItems()
        }
      }
    }
  }

  return (
    <Container>
      <StickyMessages />
      <FlatList
        ref={flatList}
        data={messages}
        removeClippedSubviews={Platform.OS !== 'web'}
        renderItem={renderItem}
        keyExtractor={(item, i) => `${item.id}-${item.appear_in}-${i}`}
        onScrollToIndexFailed={onScrollToIndexFailed}
        onContentSizeChange={onContentSizeChange}
        onScroll={onScroll}
        onRefresh={props.loadMoreItems}
        refreshing={false}
      />
      <MessageForm switcher={props.tabs.some(tab => tab.id === 'qa')} />
    </Container>
  )
}
Chat.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.array,
  loadMoreItems: PropTypes.func,
}

const Container = styled.View`
  flex: 1;
`

export default React.memo(Chat)

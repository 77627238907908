import * as Notifications from 'expo-notifications'
import Bugsnag from '@bugsnag/react-native'
import * as Linking from 'expo-linking'
import React, { useState, useEffect } from 'react'
import { Alert, Platform, Vibration } from 'react-native'
import * as Localization from 'expo-localization'
import { useDispatch, batch } from 'react-redux'
import styled from 'styled-components/native'
import Portal from '@burstware/react-native-portal'
import Home from './components/Home'
import {
  fetchViewer,
  setViewerStatus,
  removeSession,
  clearMessages,
  clearQuestions,
  clearFiles,
  clearDirectMessages
} from './actions'
import visitor from './services/visitor'
import storage from './services/storage'
import i18n from './services/i18n'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const Main = () => {
  const [token, setToken] = useState(null)
  const dispatch = useDispatch()
  i18n.userLocale = Localization.locale.split('-')[0]

  const startWebinar = cidToken => {
    setToken(cidToken)
    if (cidToken && visitor.token && visitor.token === cidToken) {
      return
    }

    batch(() => {
      dispatch(removeSession())
      dispatch(clearMessages())
      dispatch(clearQuestions())
      dispatch(clearFiles())
      dispatch(clearDirectMessages())
      dispatch(fetchViewer(cidToken))
    })
  }

  const fetchTokenFromStorage = () => {
    storage.get('cid').then(cid => {
      cid ? startWebinar(cid) : dispatch(setViewerStatus('ready'))
    })
  }

  const fetchTokenFromUrl = url => {
    let cid
    if (url) {
      const { queryParams } = Linking.parse(url)
      cid = queryParams && queryParams.cid
    }

    cid ? startWebinar(cid) : fetchTokenFromStorage()
  }

  const notificationReceived = () => {
    Vibration.vibrate()
  }

  const notificationClicked = notification => {
    const data = notification.notification.request && notification.notification.request.content && notification.notification.request.content.data
    if (data && data.cid) {
      startWebinar(data.cid)
    } else if (notification.data && notification.data.link) {
      Alert.alert(
        notification.data.title,
        notification.data.body,
        [
          { text: 'No' },
          { text: 'Register', onPress: () => Linking.openURL(notification.data.link) }
        ]
      )
    }
  }

  const subscribeToBranch = async () => {
    if (!mobile) return

    const { default: branch } = await import('react-native-branch')

    return branch.subscribe(bundle => {
      if (bundle && bundle.params && !bundle.error) {
        if (bundle.params.cid) {
          startWebinar(bundle.params.cid)
        } else if (bundle.uri) {
          fetchTokenFromUrl(bundle.uri)
        } else {
          fetchTokenFromStorage()
        }
      } else {
        fetchTokenFromStorage()

        if (bundle.error) {
          Bugsnag.notify(new Error('Branch error'), event => {
            event.addMetadata('bundle', bundle)
          })
        }
      }
    })
  }

  useEffect(() => {
    const initApp = async () => {
      if (mobile) {
        Linking.addEventListener('url', event => fetchTokenFromUrl(event.url))
        const branchSubscription = await subscribeToBranch()
        const notificationReceivedListener = Notifications.addNotificationReceivedListener(notificationReceived)
        const notificationClickedListener = Notifications.addNotificationResponseReceivedListener(notificationClicked)

        return () => {
          if (typeof branchSubscription === 'function') branchSubscription()
          if (notificationReceivedListener) Notifications.removeNotificationSubscription(notificationReceivedListener)
          if (notificationClickedListener) Notifications.removeNotificationSubscription(notificationClickedListener)
        }
      }
        Linking.getInitialURL().then(url => fetchTokenFromUrl(url))
    }
    initApp()
  }, [])

  return (
    <>
      <StyledScrollView
        contentInsetAdjustmentBehavior="automatic"
        contentContainerStyle={{ flex: 1 }}
      >
        <Portal.Host>
          <Home token={token} setToken={setToken} />
        </Portal.Host>
      </StyledScrollView>
    </>
  )
}

const StyledScrollView = styled.View`
  flex: 1;
`

export default Main
